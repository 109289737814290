/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import Shimmer from "@fdn/bs_shimmer";

const Desktop = () => (
	<>
		<div className="navbar-header">
			<div className="top-menu">
				<div className="wrapper">
					<div className="title">
						<Shimmer id="title" height="24px" width="175px" margin="0" />
					</div>
					<div className="menus">

						<Shimmer id="menus" height="24px" width="534px" margin="0" />
					</div>
				</div>
			</div>
			<div className="navbar-content-wrapper">
				<div className="navbar-inner-content-wrapper">
					<div className="main-logo-wrapper">

						<Shimmer id="main-logo-wrapper" height="45px" width="135px" margin="0" />
					</div>
					<div className="search-input-wrapper">
						<Shimmer id="search-input-wrapper" height="36px" width="100%" margin="0" />
					</div>
					<div className="header-right-menu">
						<Shimmer id="header-right-menu" height="39px" width="135px" margin="0 12px 0 0" />

						<Shimmer id="cart" height="22px" width="24px" margin="0 10px 0 0" />
						<Shimmer id="notification" height="22px" width="24px" margin="0" />
					</div>
				</div>
			</div>
			<div className="child-component-wrapper">
				<div className="category-menu">
					<div className="container">
						<div className="parent">

							<Shimmer id="parent" height="25px" width="50vw" margin="0" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<style jsx>
			{`
				.navbar-header {
          width: 100vw;
          box-sizing: border-box;
          background-color: #FFF;
          position: relative;
				}
        .top-menu {
          background: #FFF;
          box-shadow: inset 0px -1px 0px #dfe3e8;
          font-style: normal;
      }
      .top-menu .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: auto;
        max-width: 1200px;
        margin: 0 auto;
        padding: 8px 15px;
    }
    .top-menu .menus {
      display: flex;
      align-items: center;
  }
  .navbar-content-wrapper {
    width: 100%;
    position: relative;
    background-color: #FFF;
}
.navbar-inner-content-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px 15px;
}
.main-logo-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.search-input-wrapper {
  width: 50%;
  position: relative;
}
.header-right-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(50% - 245px);
}
.child-component-wrapper {
  width: 100%;
}
.category-menu {
  height: 56px;
  border-color: #dfe3e8 transparent#dfe3e8 transparent;
  border-style: solid solid solid solid;
  border-width: 1px 0 1px 0;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 56px;
  position: relative;
}
            `}
		</style>
	</>
);

export default Desktop;
