/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Shimmer from "@fdn/bs_shimmer";

const Review = () => (
	<div className="container-desktop grid">
		<div className="col-2 side-profile display-flex">
			<div className="header-top">
				<img
					src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/placeholder_user.png`}
					alt=""
				/>
				<div className="user-info">
					<Shimmer id="username" height="10px" width="5vw" />
					<p>
						<Shimmer id="skin_type" height="10px" width="5vw" />
					</p>
				</div>
			</div>
		</div>
		<div className="col-10">
			<div className="container-desktop-header">
				<div className="header-middle">
					<Shimmer id="rating" height="10px" width="100vh" />
					<Shimmer id="date" height="10px" width="20vh" />
				</div>
				<div className="header-bottom">
					<Shimmer id="recommended" height="10px" width="40vh" />
				</div>
			</div>
			<div className="container-desktop-comment">
				<Shimmer id="desktop-comment" height="10px" width="100%" />
			</div>
			<div className="container-desktop-footer">
				<Shimmer
					id="footer-desktop-comment"
					height="10px"
					width="50%"
				/>
			</div>
		</div>
		<style>
			{`
				.container-desktop {
					width: 90%;
					height: auto;
					box-sizing: border-box;
					padding: 28px 0 28px 0;
                    border-bottom: 1px solid #DFE3E8;
				}

                .side-profile {
                    align-self: start;
                    margin: 14px 14px 20px 0;
                }

                .circle {
                    display: inline-block;
                    border-radius: 50%;
                    padding: .02em .45em;
                    background: #50BF78;
                }

				.header-top {
					display: flex;
					flex-direction: column;
					align-items: start;
					font-size: 16px;
					line-height: 16px;
					font-weight: 400;
				}

				.header-top img {
					width: 60px;
					height: 60px;
					border-radius: 100%;
					margin-right: 24px;
					margin-bottom: 16px;
				}

				.user-info {
					display: flex;
					flex-direction: column;
					word-break: break-all
				}

				.user-info span {
					margin-bottom: 4px;
				}

                .user-info p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    margin: 0 0 4px;
                    color: #637381;
				}

				.header-middle {
					display: flex;
					flex: row;
					justify-content: space-between;
					margin: 14px 0;
					align-items: center;
				}

				.header-middle span:last-child {
					color: #919EAB;
					font-size: 16px;
					font-weight: 400;
					line-height: 16px;
					letter-spacing: 0em;
				}

				.header-bottom {
					display: flex;
					align-items: center;
				}

				.header-bottom span:last-child {
					margin-left: 8px;
					color: #454F5B;
					font-weight: 600;
					line-height: 100%;
				}

				.container-desktop-comment {
					color: #454F5B;
					margin: 16px 0 20px 0;
				}

				.container-desktop-footer {
					color: #454F5B;
					display: flex;
					align-items: center;
				}

				.container-desktop-footer span:first-child {
					margin-right: 10px;
                    display: flex;
				}

				.container-desktop-footer span:last-child {
					margin-left: 10px;
					font-weight: 600;
					color: #000000; 
				}
			`}
		</style>
	</div>
);

export default Review;
