import React from "react";
import PropTypes from "prop-types";
import Shimmer from "@fdn/bs_shimmer";

const Similar = ({ horizontal }) => (horizontal ? (
	<section>
		<div className="head">
			<Shimmer id="similar-title-shimmer" height="10px" width="200px" />
		</div>

		<div className="content">

			<div className="col-3">
				<Shimmer id="image" height="220px" width="100%" />
				<Shimmer id="rate" height="10px" width="41px" />
				<Shimmer id="brand" height="10px" width="89px" />
				<Shimmer id="subtitle" height="10px" width="100%" />
				<Shimmer id="price" height="10px" width="89px" />
			</div>
		</div>
	</section>
)
	: (
		<section>
			<div className="head">
				<Shimmer id="similar-title-shimmer" height="32px" width="200px" />
			</div>

			<div className="content">
				<div className="col-3">
					<Shimmer id="image" height="220px" width="100%" />
					<Shimmer id="rate" height="10px" width="41px" />
					<Shimmer id="brand" height="10px" width="89px" />
					<Shimmer id="subtitle" height="10px" width="100%" />
					<Shimmer id="price" height="10px" width="89px" />
				</div>
			</div>
		</section>
	));
Similar.propTypes = {
	horizontal: PropTypes.bool,
};

Similar.defaultProps = {
	horizontal: false,
};

export default Similar;
