import React from "react";
import Shimmer from "@fdn/bs_shimmer";
import Tabs from "@fdn/tabs";

const Desktop = () => (
	<>
		<div className="container">
			<div className="grid">
				<div className="col-12">
					<Shimmer
						id="breadcrumb-shimmer"
						height="15px"
						width="200px"
					/>
					<div className="grid">
						<div className="col-5">
							<Shimmer height="486px" width="486px" />
						</div>
						<div className="col-7">
							<section className="product-info">
								<div className="title">
									<p className="brand-name">
										<Shimmer
											id="brand-name-shimmer"
											height="32px"
											width="110px"
										/>
									</p>
								</div>
								<div className="info">
									<p className="product-name">
										<Shimmer
											id="product-name-shimmer"
											height="22px"
											width="250px"
										/>
									</p>
									<div className="product-rating">
										<Shimmer
											id="product-rating-shimmer"
											height="22px"
											width="150px"
										/>
									</div>
								</div>
								<div className="product-price">
									<Shimmer
										id="price-shimmer"
										height="32px"
										width="110px"
									/>
								</div>
								<div className="product-qty">
									<Shimmer
										id="price-qty-shimmer"
										height="32px"
										width="20%"
										margin="0 15px 0 0"
									/>

									<Shimmer
										id="buynow-shimmer"
										height="32px"
										width="20%"
										margin="0 15px"
									/>

									<Shimmer
										id="addtocart-shimmer"
										height="32px"
										width="20%"
										margin="0 15px"
									/>
								</div>
								<Tabs
									on_click={() => {}}
									tab_active="description"
									border_bottom="1px solid #DFE3E8"
									font_family="Creato Display"
									border_active_height="1px"
								>
									<div label="description">
										<Shimmer
											id="product-desc-shimmer"
											height="32px"
											width="100%"
											margin="10px 0"
										/>
									</div>
									<div label="details">
										<Shimmer
											id="product-detail-shimmer"
											height="32px"
											width="100%"
											margin="10px 0"
										/>
									</div>
								</Tabs>
							</section>
						</div>
					</div>
				</div>
			</div>
		</div>
		<style jsx>
			{`
				.product-info {
					padding: 0 0 0 40px;
				}
				.product-info .title {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.product-info .title p {
					margin: 0;
				}
				.product-info .title p.brand-name {
					font-weight: 600;
					font-style: normal;
					font-size: 24px;
					line-height: 32px;
				}
				.product-info .title p.wishlist {
					display: flex;
					align-items: center;
					justify-content: center;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				.product-info .title p.wishlist:hover {
					cursor: pointer;
				}
				.product-info .title p.wishlist span {
					margin-left: 10px;
				}

				.product-info .info {
					margin-bottom: 30px;
				}
				.product-info .info .product-name {
					font-weight: 400;
					font-style: normal;
					font-size: 16px;
					line-height: 20px;
					margin: 10px 0;
				}
				.product-info .info .product-rating {
					display: flex;
				}
				.product-info .info .product-rating div {
					display: flex;
					align-items: center;
					padding: 0 15px 0 0;
				}
				.product-info .info .product-rating div:not(:first-child) {
					border-left: 1px solid #dfe3e8;
					padding: 0 15px;
				}
				.product-info .info .product-rating div span.value {
					margin: 0 5px 0 0;
				}
				.product-info .info .product-rating div.reviews,
				.product-info .info .product-rating div.recomendations {
					font-weight: 400;
					font-style: normal;
					font-size: 14px;
					line-height: 14px;
					color: #637381;
				}

				.product-info .product-price {
					margin-bottom: 30px;
				}
				.product-info .product-price .price {
					font-weight: 400;
					font-style: normal;
					font-size: 14px;
					line-height: 14px;
					color: #637381;
					text-decoration: line-through;
					margin: 0;
				}
				.product-info .product-price .price-discount {
					display: flex;
					align-items: center;
					font-weight: 600;
					font-style: normal;
					font-size: 24px;
					line-height: 24px;
					margin: 5px 0 0 0;
				}
				.product-info .product-price .price-discount span {
					display: flex;
					align-items: center;
					height: 24px;
					background: #f7526e;
					font-weight: 400;
					font-style: normal;
					font-size: 12px;
					line-height: 12px;
					color: #fff;
					padding: 3px 5px;
					margin-left: 15px;
				}
				.product-description,
				.product-details {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 28px;
					letter-spacing: 0.04em;
					color: #323942;
				}

				.product-info .product-qty {
					display: flex;
					align-items: center;
					margin-bottom: 16px;
				}

				.product-info .product-limited {
					margin: 0 0 32px 0;
				}

				.product-info .product-limited span {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					letter-spacing: 0.04em;
					color: #ff4d4f;
				}

				.wrapper-review {
					margin-top: 100px;
				}
				.similar-product {
					padding-left: 5rem;
				}
				.product-review .head,
				.similar-product .head {
					display: flex;
					align-items: center;
					padding: 16px 10px 16px 0px;
				}
				.product-review .head p.title,
				.similar-product .head p.title {
					display: flex;
					align-items: center;
					font-weight: 600;
					font-style: normal;
					font-size: 20px;
					line-height: 32px;
					margin: 0;
				}
				.product-review .head p.title span {
					font-weight: 400;
					font-style: normal;
					font-size: 14px;
					line-height: 16px;
					color: #637381;
					margin-left: 10px;
				}

				.detail-product-desktop section.product-recommendation .head {
					padding: 0 15px 20px 15px;
				}
				.detail-product-desktop section.product-recommendation .head p {
					margin: 0;
					font-weight: 600;
					font-style: normal;
					font-size: 14px;
					line-height: 16px;
				}
				.detail-product-desktop
					section.product-recommendation
					.content {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 10px;
				}
			`}
		</style>
	</>
);
export default Desktop;
