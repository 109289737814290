/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import Shimmer from "@fdn/bs_shimmer";

const Mobile = () => (
	<>
		<section className="product-recommendation">
			<div className="head">
				<Shimmer id="similar-title-shimmer" height="10px" width="200px" />
			</div>
			<div className="content">
				<div className="child-list-recommendation-mobile">
					<div className="fdn-product-card-shimmer-mobile">

						<Shimmer id="image" height="156px" width="100%" />
						<Shimmer id="rate" height="10px" width="41px" />
						<Shimmer id="brand" height="10px" width="89px" />
						<Shimmer id="subtitle" height="10px" width="100%" />
						<Shimmer id="price" height="10px" width="89px" />
					</div>

					<div className="fdn-product-card-shimmer-mobile">

						<Shimmer id="image" height="156px" width="100%" />
						<Shimmer id="rate" height="10px" width="41px" />
						<Shimmer id="brand" height="10px" width="89px" />
						<Shimmer id="subtitle" height="10px" width="100%" />
						<Shimmer id="price" height="10px" width="89px" />
					</div>
				</div>
			</div>

			<style>
				{`
				.product-recommendation .content .child-list-recommendation-mobile {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 15px;
				}
                .product-recommendation .head {
                    padding: 0 15px;
                }
                .product-recommendation .content {
                    padding: 0 15px;
                }
            `}
			</style>
		</section>
	</>
);

export default Mobile;
