/* eslint-disable react/no-children-prop */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import Desktop from "./desktop";
import Mobile from "./mobile";

const Section = ({
	isMobile, isMozilla, type, ...otherProps
}) => {

	if (!isMobile) {

		return (
			<Desktop
				isMozilla={isMozilla}
				type={type}
				{...otherProps}
			/>
		);

	}
	return (
		<Mobile
			isMozilla={isMozilla}
			type={type}
			{...otherProps}
		/>
	);

};

Section.propTypes = {
	isMobile: PropTypes.bool,
	isMozilla: PropTypes.bool,
	type: PropTypes.string,
};

Section.defaultProps = {
	isMobile: false,
	isMozilla: false,
	type: "default",
};

export default Section;
