/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Shimmer from "@fdn/bs_shimmer";

const Review = () => (
	<div className="container-mobile">
		<div className="container-mobile-header">
			<div className="header-top">
				<img
					src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/placeholder_user.png`}
					alt=""
				/>
				<div className="user-info">
					<Shimmer id="username" height="10px" width="70vw" />
					<div className="desc">
						<Shimmer id="skin" height="10px" width="40vw" />

						<Shimmer id="age" height="10px" width="40vw" />
					</div>
				</div>
			</div>
			<div className="header-middle">
				<span>
					<Shimmer id="rating" height="10px" width="40vw" />
				</span>
				<span>
					<Shimmer id="date" height="10px" width="40vw" />
				</span>
			</div>
			<div className="header-bottom">
				{/* <span><div className="icon-ic_thumbs_up_active" style={{ color: "#50BF78", fontSize: "25px" }} /></span> */}
				<Shimmer id="recommended" height="10px" width="40vw" />
			</div>
		</div>
		<div className="container-mobile-comment">
			<span>
				<Shimmer id="mobile-comment" height="10px" width="80vw" />
			</span>
		</div>
		<div className="container-mobile-footer">
			<Shimmer id="usage-periode-comment" height="10px" width="40vw" />
		</div>
		<style>
			{`
				.container-mobile {
					width: 100%;
					height: auto;
					box-sizing: border-box;
					padding: 16px;
				}

				.header-top {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 16px;
					line-height: 16px;
					font-weight: 400;
				}

				.header-top img {
					width: 45px;
					height: 45px;
					border-radius: 100%;
					margin-right: 24px;
				}

				.user-info {
					display: flex;
					flex-direction: column;
					font-weight: 400;
					font-style: normal;
					font-size: 14px;
					line-height: 16px;
					word-break: break-all
				}

                .circle {
                    display: inline-block;
                    border-radius: 50%;
                    padding: .02em .45em;
                    background: #50BF78;
                }

				.user-info span:first-child {
					margin-bottom: 4px;
					font-weight: 600;
				}

				.user-info .desc span {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 22px;
					color: #637381;
					position: relative;
					padding: 0 8px 0 0;
				}

				.user-info .desc span:not(:first-child) {
					margin-left: 8px;
				}

				.user-info .desc span.skin:after {
					content: "";
					width: 1.5px;
					height: 75%;
					background-color: #DFE3E8;
					right: 0;
					position: absolute;
					display: block;
					top: 15%;
				}

				.header-middle {
					display: flex;
					flex: row;
					justify-content: space-between;
					margin: 14px 0;
					align-items: center;
				}

				.header-middle span:last-child {
					color: #919EAB;
					font-size: 14px;
					font-weight: 400;
					line-height: 16px;
					letter-spacing: 0em;
				}

				.header-bottom {
					display: flex;
					align-items: center;
				}

				.header-bottom span:last-child {
					margin-left: 8px;
					color: #454F5B;
					font-weight: 600;
					line-height: 100%;
					font-size: 12px;
					line-height: 12px;
				}

				.container-mobile-comment {
					color: #454F5B;
					margin: 16px 0 20px 0;
					font-weight: 400;
					font-style: normal;
					font-size: 14px;
					line-height: 22px;
				}

				.container-mobile-comment span p.text {
					text-align: justify;
				}

				.container-mobile-comment span p.text span span.dots span.read-more,
				.container-mobile-comment span p.text span span.read-less {
					color: #1B8884;
					cursor: pointer;
				}

				.container-mobile-footer {
					color: #454F5B;
					display: flex;
					align-items: center;
					font-size: 14px;
					line-height: 16px;
				}

				.container-mobile-footer span:not(:first-child) {
					margin-left: 10px;
				}

				.container-mobile-footer span:last-child {
					font-weight: 600;
					color: #000000; 
					margin-left: auto;
				}
			`}
		</style>
	</div>
);

export default Review;
