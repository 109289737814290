import React from "react";
import Shimmer from "@fdn/bs_shimmer";
import Tabs from "@fdn/tabs";

const Mobile = () => (
	<>
		<Shimmer
			id="breadcrumb-shimmer"
			height="15px"
			width="200px"
			margin="15px"
		/>
		<div className="detail-product">
			<section className="product-info">
				<Shimmer id="product-img-shimmer" height="300px" width="100%" />
				<p className="brand-name">
					<Shimmer
						id="brand-name-shimmer"
						margin="10px 0"
						height="15px"
						width="32px"
					/>
				</p>
				<p className="product-name">
					<Shimmer
						id="product-name-shimmer"
						margin="10px 0"
						height="15px"
						width="200px"
					/>
				</p>
				<div className="product-rating">
					<Shimmer
						id="product-rating-shimmer"
						margin="10px 0"
						height="15px"
						width="100%"
					/>
				</div>
				<div className="product-price">
					<Shimmer
						id="product-price-shimmer"
						margin="10px 0"
						height="15px"
						width="20%"
					/>
				</div>
				<div className="product-qty">
					<Shimmer
						id="product-qty-txt-shimmer"
						margin="10px 0"
						height="15px"
						width="20%"
					/>
					<Shimmer
						id="product-qty-shimmer"
						margin="10px 0"
						height="15px"
						width="30%"
					/>
				</div>
			</section>
			<section>
				<Tabs
					is_mobile
					on_click={() => {}}
					tab_active="description"
					border_bottom="1px solid #DFE3E8"
					font_family="Creato Display"
					border_active_height="2px"
				>
					<div label="description">
						<Shimmer
							id="product-desc-shimmer"
							margin="10px"
							height="25px"
							width="95%"
						/>
					</div>
					<div label="details">
						<Shimmer
							id="product-detail-shimmer"
							margin="10px"
							height="25px"
							width="95%"
						/>
					</div>
				</Tabs>
			</section>
		</div>
		<style>
			{`
            .detail-product {
                background: #F0F3F7;
                position: relative;
            }
            .detail-product section  {
                background: #fff;
                padding: 10px 0;
                margin: 0 0 20px 0;
            }
            .detail-product .product-info {
                padding: 0 15px 15px 15px;
            }
            .detail-product .product-info .brand-name {
                font-weight: 600;
                font-style: normal;
                font-size: 16px;
                line-height: 16px;
                margin: 20px 0 10px 0;
            }
            .detail-product .product-info .product-name {
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                line-height: 16px;
                margin: 10px 0;
            }
            .detail-product .product-info .product-rating {
                display: flex;
                margin: 0 0 20px 0;
            }
            .detail-product .product-info .product-rating div {
                display: flex;
                align-items: center;
                padding: 0 10px 0 0;
            }
            .detail-product .product-info .product-rating div:not(:first-child) {
                border-left: 1px solid #DFE3E8;
                padding: 0 10px;
            }
            .detail-product .product-info .product-rating div span.value {
                margin: 0 5px 0 0;
            }
            .detail-product .product-info .product-rating div.reviews,
            .detail-product .product-info .product-rating div.recomendations {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                line-height: 14px;
                color: #637381;
            }

            .detail-product .product-description, .detail-product .product-details {
                padding: 16px;
            }

            .detail-product .product-info .product-price {
                margin: 0 0 20px 0;
            }
            .detail-product .product-info .product-price .price {
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 12px;
                color: #637381;
                text-decoration: line-through;
                margin: 0;
            }
            .detail-product .product-info .product-price .price-discount {
                font-weight: 600;
                font-style: normal;
                font-size: 16px;
                line-height: 16px;
                margin: 5px 0 0 0;
            }
            .detail-product .product-info .product-price .price-discount span {
                height: 20px;
                background: #F7526E;
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 12px;
                color: #fff;
                padding: 3px 5px;
                margin: 0 0 0 5px;
            }

            .product-description, .product-details {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.04em;
                color: #454F5B;
            }

            .detail-product .product-info .product-qty {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .detail-product .product-info .product-qty p {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                line-height: 16px;
            }

            .detail-product .product-info .product-limited {
                margin: 16px 0 0;
                text-align: right;
            }

            .detail-product .product-info .product-limited span {
                
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.04em;
                color: #FF4D4F;
            }

            .detail-product section.product-review .head {
                display: flex;
                align-items: center;
                padding: 0 15px 25px 15px;
            }
            .detail-product section.product-review .head p.title {
                font-weight: 600;
                font-style: normal;
                font-size: 24px;
                line-height: 32px;
                margin: 0;
            }
            .detail-product section.product-review .head p.desc {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                line-height: 16px;
                color: #637381;
                margin: 0;
            }

            .detail-product section.product-recommendation .head {
                padding: 0 15px 20px 15px;
            }
            .detail-product section.product-recommendation .head p {
                margin: 0;
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 16px;
            }
            .detail-product section.product-recommendation .content {
                padding: 0 15px;
            }

            .action-product  {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px;
                grid-gap: 5px;
                height: 64px;
                background: #fff;
                position: fixed;
                width: 100%;
                bottom: 0;
                box-sizing: border-box;
                box-shadow: 0px -3px 1px rgba(145, 158, 171, 0.2);
                max-width: 100vw;
            }
            .action-product span.action-wishlist {
                display: flex;
                justify-content: flex-start;
                width: 10%;
                -webkit-user-select: none;       
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .action-product span.action-wishlist svg:hover {
                cursor: pointer;
            }
            .action-product button#buy-now {
                width: 35%;
            }
            .action-product button#add-cart {
                width: 50%;
            }
            @media screen and (max-width: 425px) {
                .action-product  {
                    width: 100vw;
                }
            }
        `}
		</style>
	</>
);

export default Mobile;
