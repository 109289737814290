/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import Similar from "./similar";
import PDP from "./pdp";
import Review from "./review";
import Default from "./default";
import Banner from "./banner";
import NavbarHeader from "./navbarHeader";

const Template = [
	{
		id: "pdp",
		component: PDP,
	},
	{
		id: "similar",
		component: Similar,
	},
	{
		id: "review",
		component: Review,
	},
	{
		id: "default",
		component: Default,
	},
	{
		id: "banner",
		component: Banner,
	},
	{
		id: "navbar-header",
		component: NavbarHeader,
	},
];

const Mobile = ({
	type,
	...otherProps
}) => (
	Template.filter((item) => item.id === type).map((style, idx) => (
		<style.component
			key={String(idx)}
			type={type}
			{...otherProps}
		/>
	))
);

Mobile.propTypes = {
	type: PropTypes.string,
};

export default Mobile;
