import React from "react";
import PropTypes from "prop-types";
import Shimmer from "@fdn/bs_shimmer";

const Banner = ({ isMozilla }) => (
	<div className="section">
		<div className="container">
			<div className={isMozilla ? "display-flex" : "grid"}>
				<div className="col-12">
					<Shimmer width="1200px" height="350px" />
				</div>
			</div>
		</div>
		<style jsx>
			{`
				.section {
					width: 100%;
					box-sizing: border-box;
					padding: 20px;
				}
				.head {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 32px;
				}
				.head .title {
					font-weight: 600;
					font-style: normal;
					font-size: 24px;
					line-height: 24px;
				}
				.head a {
					font-weight: 600;
					font-style: normal;
					font-size: 16px;
					line-height: 24px;
					color: #1b8884;
				}
				.section .container .display-flex .col-12 {
					width: 100%;
				}
			`}
		</style>
	</div>
);

Banner.propTypes = {
	isMozilla: PropTypes.bool.isRequired,
};

export default Banner;
