/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import Shimmer from "@fdn/bs_shimmer";

const Mobile = () => (
	<>
		<div className="navbar-header">
			<div className="navbar-content-wrapper">
				<div className="burger-menu">

					<Shimmer id="burger-menu" height="100%" width="100vw" />
				</div>
				<div className="main-logo-wrapper">

					<Shimmer id="main-logo-wrapper" height="28px" width="100vw" />
				</div>
				<div className="header-right-menu">
					<div>

						<Shimmer id="header-right-menu" height="30.5px" width="58px" />
					</div>

				</div>
				<div className="child-component-wrapper">
					<div className="wrapper-search">

						<Shimmer id="child-component-wrapper" height="34px" width="100%" />
					</div>

				</div>
			</div>
		</div>
		<style jsx>
			{`
				.navbar-header {
          position: static;
          width: 100vw;
          z-index: 3;
          background-color: #FFF;
          box-shadow: 0px 4px 10px rgb(145 158 171 / 10%);
          transition: all .3s ease;
				}
        .navbar-content-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          position: relative;
          padding: 15px;
      }
      .burger-menu {
        position: relative;
        display: block;
        margin-right: 15px;
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-logo-wrapper {
      width: 112.96px;
      overflow: hidden;
  }
  .header-right-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}
.child-component-wrapper {
  width: 100%;
  margin: 10px 0 0 0;
}
.wrapper-search {
  position: relative;
}
            `}
		</style>
	</>
);

export default Mobile;
